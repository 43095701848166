const style = ({ theme, isAmp }) => ({
    margin: 0,
    backgroundColor: theme.colors.background.mainDefault,
    paddingTop: '90px',

    [theme.breakpoints.up('md')]: {
        paddingTop: '50px',
        '&.has_subnavigation': {
            paddingTop: '90px' // 50px mainNav + 40px subNav
        }
    }
})

export default style
