import { toRem, toRems } from '../../helpers/theme'

const style = ({ theme, isAmp }) => {
    const { panel } = theme.global.navigation
    const config = {
        header: {
            height: {
                default: toRem(theme.global.header.height.md.open),
                xs: toRem(theme.global.header.height.xs.open)
            }
        },
        maxWidth: {
            lg: toRem(panel.maxWidth.lg - 80),
            md: toRem(panel.maxWidth.md),
            sm: toRem(panel.maxWidth.sm)
        }
    }

    return {
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: theme.colors.footer.background,
        position: 'relative',
        zIndex: theme.zIndex.footer,
        '.Footer': {
            '&_main': {
                alignItems: 'flex-start'
            },
            '&_container': {
                position: 'relative',
                margin: 'auto',
                width: '100%',
                padding: toRems([0, 30, 30]),

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

                ...(!isAmp && {
                    [theme.breakpoints.up('md')]: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: toRems([0, 35, 0, 25]),
                        maxWidth: config.maxWidth.lg,
                        height: 'auto'
                    },
                    [theme.breakpoints.up('lg')]: {
                        paddingLeft: toRem(25)
                    },

                    [theme.breakpoints.up('xl')]: {
                        paddingLeft: toRem(15)
                    }
                })
            },
            '&_info': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                ...(!isAmp && {
                    [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: toRems([15, 0]),
                        justifyContent: 'space-between',
                        borderTop: `${toRem(1)} solid ${theme.colors.border?.darker || theme.colors.devWarning}`,
                        borderBottom: `${toRem(1)} solid ${theme.colors.border?.darker || theme.colors.devWarning}`
                    }
                }),
                '&_left': {
                    borderTop: `${toRem(1)} solid ${theme.colors.border?.darker || theme.colors.devWarning}`,
                    borderBottom: `${toRem(1)} solid ${theme.colors.border?.darker || theme.colors.devWarning}`,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    [theme.breakpoints.up('md')]: {
                        flex: '1 0 1',
                        border: 'none'
                    }
                },
                '&_right': {
                    marginTop: toRem(30),
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: toRem(20),
                    span: {
                        height: toRem(20)
                    },
                    [theme.breakpoints.up('md')]: {
                        justifyContent: 'flex-end',
                        marginTop: 0,
                        flex: '0 1 auto'
                    }
                }
            },
            '&_rss': {
                color: theme.colors.primary,
                fontSize: toRem(14),
                lineHeight: toRem(17),
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                svg: {
                    path: {
                        color: 'currentColor'
                    }
                }
            },
            '&_partners': {
                display: 'flex',
                flexDirection: 'column',
                marginTop: toRem(20),
                marginBottom: toRem(25),

                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                },
                '&_item': {
                    '&:first-of-type': {
                        flex: '1 0 auto'
                    },
                    flex: '1 1 auto',
                    marginBottom: toRem(15),
                    [theme.breakpoints.up('md')]: {
                        flex: '1 1 auto',
                        maxWidth: '100%'
                    }
                }
            },
            '&_logo': {
                '&_rtl svg': {
                    width: toRem(70),
                    height: toRem(22)
                },
                '&_net': {
                    svg: {
                        width: toRem(80),
                        height: toRem(20),
                        '.colorMe': {
                            fill: theme.colors.brand
                        }
                    }
                }
            },
            '&_signature': {
                color: theme.colors.footer?.text?.partner || theme.colors.devWarning,
                fontSize: toRem(14),
                lineHeight: toRem(20),
                marginBottom: toRem(30),
                textAling: 'left',
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    marginBottom: toRem(40)
                }
            },
            '&_channels': {
                [theme.breakpoints.down('md')]: {
                    marginTop: '20px',
                    borderTop: `${toRem(1)} solid ${theme.colors.border?.darker || theme.colors.devWarning}`,
                    borderBottom: `${toRem(1)} solid ${theme.colors.border?.darker || theme.colors.devWarning}`
                },

                'svg path': {
                    stroke: 'white'
                }
            },

            '&_channel': {
                display: 'inline-block',
                marginRight: '10px',
                lineHeight: '34px',

                svg: {
                    width: '16px',
                    height: '12px',

                    [theme.breakpoints.up('md')]: {
                        width: '20px',
                        height: '16px'
                    }
                },
                span: {
                    fontFamily: 'proxima-nova-condensed, ArialToProximaNovaCond',
                    fontWeight: 400,
                    fontSize: '13px',
                    color: '#F15B25',

                    [theme.breakpoints.up('md')]: {
                        fontSize: '15px'
                    }
                },
                '.channel_icon': {
                    position: 'relative',
                    top: '1px',

                    '&.is_Youtube': {
                        top: '2px',
                        marginRight: '2px'
                    },

                    '&.is_Instagram': {
                        marginRight: '2px'
                    },
                    '&.is_X path': {
                        stroke: 'transparent',
                        fill: '#fff'
                    }
                },
                '.channel_name': {
                    marginLeft: '1px'
                }
            }
        }
    }
}

export default style
