import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { Icon, MailIcon } from '../Icon'
import styles from './FreemailButton.style'

const FreemailButtonStyled = styled.a(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FreemailButton({ className, variant, ...rest }) {
    const [isDesktop] = useBreakpoints('md')
    return (
        <FreemailButtonStyled
            type="button"
            href="https://freemail.net.hr"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Freemail"
            variant={variant}
            {...rest}>
            {(!isDesktop || variant === 'menu') && <Icon className="Freemail_icon" icon={MailIcon} />}
            {(isDesktop || variant === 'menu') && <span className="Freemail_title">FREEMAIL</span>}
        </FreemailButtonStyled>
    )
}

const freemailButtonVariants = Object.freeze({
    HEADER: 'header',
    MENU: 'menu'
})

FreemailButton.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(freemailButtonVariants)])
}

FreemailButton.defaultProps = {
    className: undefined,
    variant: freemailButtonVariants.HEADER
}

export default withErrorBoundary(FreemailButton, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[FreemailButton]: ', error, componentStack)
    }
})
export { freemailButtonVariants }
