import { toRem, toRems } from '../../../helpers/theme'

const style = ({ theme, variant }) => ({
    display: 'flex',
    width: '100%',
    '& .searchInput': {
        width: '100%',
        border: 'none',
        backgroundColor: theme.colors.search.input.background,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontFamily: theme.typography.fontFamily.default,
        fontSize: toRem(16),
        lineHeight: 1.4,
        padding: toRems([16, 16, 16, 16]),
        color: theme.colors.search.input.text.default,
        '&:focus': {
            color: theme.colors.search.input.text.focus,
            outline: `1px solid ${theme.colors.text.accent}`, // !important`,
            backgroundColor: theme.colors.background.default
        },
        '&::placeholder': {
            fontStyle: 'italic',
            fontFamily: theme.typography.fontFamily.default,
            lineHeight: 1.4,
            color: theme.colors.search.input.text.placeholder
        }
    },
    '& .searchButton': {
        ...(variant === 'navigation' && {
            width: toRem(theme.global.header?.height?.open || 70),
            flex: '0 0 auto'
        })
    }
})

export default style
