import { toRem } from '../../helpers'

const style = ({ theme, open }) =>
    // const config = {}
    ({
        background: theme.colors.header?.background || theme.color.devWarning,
        '.Header': {
            position: 'fixed',
            top: 0,
            zIndex: theme.zIndex.header,
            transition: 'height 500ms',
            height: toRem(theme.global?.header?.height?.xs?.[open ? 'open' : 'closed'] || 50),
            width: '100vw',
            background: theme.colors.header?.background || theme.color.devWarning,
            boxShadow: `0px 3px 0px ${theme.colors.navigationShadow}, 0px 39px 0px ${theme.colors.mainBackground}`,

            [theme.breakpoints.up('md')]: {
                height: toRem(theme.global?.header?.height?.md?.[open ? 'open' : 'closed'] || 50),
                padding: '0 10px',
                boxShadow: `0px 3px 0px ${theme.colors.navigationShadow}`
            },

            '.has_subnavigation &': {
                boxShadow: `0px 3px 0px ${theme.colors.navigationShadow}, 0px 39px 0px ${theme.colors.mainBackground}`,
                transition: 'box-shadow 350ms ease-in-out'
            },

            '.main_layout &': {
                [theme.breakpoints.down('sm')]: {
                    boxShadow: `0px 3px 0px ${theme.colors.navigationShadow},
                    0px 39px 0px ${theme.colors.mainBackground}`,
                    transition: 'box-shadow 350ms ease-in-out'
                }
            },

            '.main_layout.subnav_hide &': {
                [theme.breakpoints.down('sm')]: {
                    boxShadow: `0px 3px 0px ${theme.colors.navigationShadow},
                    0px 0px 0px ${theme.colors.mainBackground}`
                }
            },

            '&_navigation': {
                display: 'flex',
                flex: '1 1 100%',
                width: '100%',
                paddingTop: open ? '7px' : 0,
                transition: 'padding-top 300ms',
                [theme.breakpoints.up('md')]: {
                    height: '100%'
                }
            },
            '&_container, &_subnavigation': {
                margin: 'auto',
                position: 'relative',
                maxWidth: toRem((theme.global?.navigation?.panel?.maxWidth?.lg || 1280) - 80),
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                width: '100%',
                height: '100%',
                [theme.breakpoints.up('md')]: {
                    padding: '0 20px',
                    alignContent: 'center',
                    flexWrap: 'no-wrap'
                }
            },
            '&_subnavigation': {
                height: '34px',
                transition: 'height 350ms ease-in-out',

                '.subnav_hide &': {
                    [theme.breakpoints.down('sm')]: {
                        height: 0
                    }
                }
            },

            // '&.subnav_hide .Header_subnavigation': {
            //     [theme.breakpoints.down('sm')]: {
            //         height: 0
            //     }
            // },

            '&_logo': {
                marginTop: open ? 0 : '20px',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                transition: 'margin-top 350ms ease-in-out',
                [theme.breakpoints.up('md')]: {
                    marginTop: 0
                },

                '&.is_faux': {
                    paddingLeft: '20px',

                    '&.is_danas': {
                        // paddingLeft: '40px'
                    }
                }
            },
            // adjust navigation padding and hide first menu (Danas section) when faux logo is visible
            '&_logo.is_faux + .Header_navigation .Menu_container': {
                paddingLeft: '10px'
                // INFO: keep this commented out for now, it's not working as expected
                // '.Menu_group:first-of-type': {
                //     display: 'none'
                // }
            },
            '&_mainButton': {
                flex: '0 0 auto',
                position: 'relative',
                width: '50px',
                height: '100%',
                overflow: 'hidden',

                span: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flex: '0 0 auto'
                }
            },
            '&_icon': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row-reverse',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                transition: 'transform,top',
                willChange: 'transform,top',
                '&.open': {
                    top: 0,
                    // visibility: 'visible',
                    transitionDuration: '0.2s',
                    transitionTimingFunction: 'ease-out',
                    transitionDelay: '0.15s'
                },
                '&.closed': {
                    top: '-50px',
                    transitionDuration: '0.2s',
                    transitionTimingFunction: 'ease-in',
                    transitionDelay: 0
                },
                [theme.breakpoints.up('md')]: {
                    position: 'relative'
                }
            },
            '&_searchButton': {
                height: '100%'
            },
            '&_backButton': {
                path: {
                    fill: 'none',
                    stroke: 'currentColor'
                }
            },
            '&_left': {
                display: 'flex',
                contentAlign: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                flexWrap: 'nowrap',
                height: '100%',
                [theme.breakpoints.down('md')]: {
                    flex: '1 1 100%'
                }
            },
            '&_right': {
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexWrap: 'no-wrap',
                    marginTop: open ? '3px' : 0,
                    '#desktopScaleDown': {
                        display: 'flex'
                    }
                }
            }
        }
    })

export default style
