import { useCallback } from 'react'
import styled from '@emotion/styled'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useNavigationStore } from '@hmn/rtl-web-core/context/navigation'
import { useBreakpoints, useGTM } from '@hmn/rtl-web-core/hooks'

import { CloseIcon, Icon, SearchIcon } from '../../Icon'
import styles from './Button.style'

const ButtonStyled = styled.div(props => ({ ...styles(props) }))

function Button({ ...rest }) {
    const [navigationStore, setNavigationStore] = useNavigationStore()

    const [isDesktop] = useBreakpoints('md')
    const { sendDataToGTM } = useGTM()
    const handleNavAction = useCallback(() => {
        if (isDesktop) {
            setNavigationStore({ isOpen: false, searchIsOpen: !navigationStore.searchIsOpen })
        } else {
            setNavigationStore({ isOpen: true, searchIsOpen: true })
        }
        sendDataToGTM(
            {
                eventCategory: 'Navigation',
                eventLabel: 'Navigation Icon'
            },
            true
        )
    }, [isDesktop, navigationStore, sendDataToGTM, setNavigationStore])

    return (
        <ButtonStyled {...rest}>
            <button type="button" onClick={handleNavAction} aria-label="Pretraga">
                <Icon size={18} icon={navigationStore.searchIsOpen ? CloseIcon : SearchIcon} />
            </button>
        </ButtonStyled>
    )
}
Button.propTypes = {}

Button.defaultProps = {}

export default withErrorBoundary(Button, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SearchButton]: ', error, componentStack)
    }
})
