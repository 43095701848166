// import { toRem, toRems } from '../../../../../../../../helpers/theme'

import { isColor, toRem } from '../../../../../../../../helpers'

const style = ({ theme, variant, color, active, hasHref, isAmp }) => {
    const customColor = isColor(color) && color
    const menuPalette = theme.colors?.navigation?.menu?.item?.[variant] ||
        theme.colors?.navigation?.menu?.item?.default || {
            default: theme.colors.devWarning,
            hover: theme.colors.devWarning
        }

    const config = {
        navigation: {
            md: {
                display: 'block',
                color: {
                    default: (active && customColor) || menuPalette.default,
                    hover: (!active && customColor) || menuPalette.hover
                },
                fontSize: toRem(14),
                lineHeight: toRem(31)
            },
            sm: {
                display: 'block',
                color: {
                    default: (active && (customColor || theme.colors.brand)) || menuPalette.default,
                    hover: (!active && (customColor || theme.colors.brand)) || menuPalette.hover
                },
                fontSize: toRem(16),
                lineHeight: toRem(35)
            }
        },

        footer: {
            md: {
                display: 'block',
                color: {
                    default: menuPalette.default,
                    hover: customColor || menuPalette.hover
                },
                fontSize: toRem(14),
                lineHeight: toRem(31)
            },
            sm: {
                display: 'block',
                color: {
                    default: menuPalette.default,
                    hover: customColor || menuPalette.hover
                },
                fontSize: toRem(16),
                lineHeight: toRem(35)
            }
        }
    }
    const variantConfig = config[variant] || {}
    const variantConfigMd = variantConfig?.md || variantConfig || {}
    if (!variantConfigMd.color) {
        variantConfigMd.color = menuPalette
    }
    const variantConfigSm = variantConfig?.sm || variantConfig || {}
    if (!variantConfigSm.color) {
        variantConfigSm.color = menuPalette
    }
    return {
        '.secondaryMenuItem': {
            transition: 'color 300ms',
            fontWeight: theme.typography.font.weight.regular,
            fontStyle: theme.typography.font.style.normal,
            fontFamily: variantConfig.fontFamily || theme.typography.fontFamilyAlt,
            fontSize: variantConfigSm.fontSize,
            lineHeight: variantConfigSm.lineHeight,
            margin: variantConfigSm.margin || toRem(0),
            marginTop: variantConfigSm.marginTop || toRem(0),
            color: variantConfigSm?.color?.default || theme.colors.devWarning,
            display: variantConfigSm?.display || 'none',
            textTransform: variantConfig?.textTransform || 'initial',
            height: variantConfigSm?.height || 'auto',
            ...(!isAmp && {
                [theme.breakpoints.up('md')]: {
                    color: variantConfigMd?.color?.default || theme.colors.devWarning,
                    display: variantConfigMd?.display || 'none',
                    fontSize: variantConfigMd.fontSize,
                    margin: variantConfigMd.margin || toRem(0),
                    marginTop: variantConfigMd.marginTop || toRem(0),
                    lineHeight: variantConfigMd.lineHeight,
                    height: variantConfigMd?.height || 'auto'
                }
            }),
            ...(hasHref && {
                '&:hover': {
                    color: variantConfigSm?.color?.hover || theme.colors.devWarning,

                    ...(!isAmp && {
                        [theme.breakpoints.up('md')]: {
                            color: variantConfigMd?.color?.hover || theme.colors.devWarning
                        }
                    })
                }
            })
        }
    }
}
export default style
