import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    width: '100%',
    '.prevNext': {
        boxShadow: `0px -5px 0px rgba(0, 0, 0, 0.05)`,
        position: 'relative',
        width: '100%',
        paddingTop: toRem(10),
        paddingBottom: toRem(12),
        background: theme.colors.background.default || theme.colors.devWarning,
        [theme.breakpoints.up('md')]: {
            paddingTop: toRem(15),
            paddingBottom: toRem(15)
        },
        '&_wrapper': {
            width: '100%',
            background: theme.colors.background.default || theme.colors.devWarning,
            zIndex: theme.zIndex.ad
        },
        '&_count': {
            flex: '0 0 auto',
            fontSize: toRem(15),
            color: theme.colors.netGrey,
            letterSpacing: '0.2em',
            fontWeight: theme.typography.fontWeight.bold
        },
        '&_button': {
            flex: '1 1 50%',
            [theme.breakpoints.down('sm')]: {
                alignSelf: 'center'
            },
            '&_wrapper': {
                margin: 'auto',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                maxWidth: toRem(1215)
            }
        }
    }
})

export default style
