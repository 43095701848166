import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { ArrowUpIcon, Icon } from '../../../../../../../../../Icon'
import styles, { arrowMotionVariants } from './MenuArrow.style'

const MenuArrowStyled = styled.div(props => ({ ...styles(props) }))

function MenuArrow({ className, open, onClick = () => {}, title }) {
    return (
        <MenuArrowStyled className={className}>
            <button type="button" aria-label={title} className="menuArrow_circle" onClick={onClick}>
                <motion.div
                    initial="closed"
                    animate={open ? 'open' : 'closed'}
                    variants={arrowMotionVariants}
                    transformTemplate={({ scaleY }) => `scaleY(${scaleY})`}
                    className="menuArrow_wrapper">
                    <Icon className="menuArrow_icon" icon={ArrowUpIcon} />
                </motion.div>
            </button>
        </MenuArrowStyled>
    )
}

MenuArrow.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    open: PropTypes.bool,
    onClick: PropTypes.func
}

MenuArrow.defaultProps = {
    open: false,
    className: undefined,
    title: undefined,
    onClick: undefined
}
export default MenuArrow
