import { useEffect, useState } from 'react'

import { useBreakpoints, useWindowScroll } from '@hmn/rtl-web-core/hooks'

export const useHeaderState = () => {
    const [isDesktop] = useBreakpoints('md')
    const { position, direction } = useWindowScroll()

    // Calculate 'shouldOpen' based only on 'isDesktop', 'position', and 'direction'.
    const shouldOpen = isDesktop || position < 10 || direction === 'up'

    const [isOpen, setOpen] = useState(shouldOpen)

    // Synchronize the 'isOpen' state with changes in 'shouldOpen'.
    useEffect(() => {
        setOpen(shouldOpen)
    }, [shouldOpen]) // Dependency on 'shouldOpen' which handles all required conditions.

    return { isOpen }
}
