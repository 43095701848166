import { useCallback, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import styles from './Panel.style'

const PanelStyled = styled.div(props => ({ ...styles(props) }))

export const usePanelTimeoutHidden = ({ delay = 300, open = false }) => {
    const panelRef = useRef(null)
    const timeoutRef = useRef(null)
    const [isDesktop] = useBreakpoints('md')
    useEffect(() => {
        const destroy = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
        if (!panelRef.current) {
            return destroy
        }
        if (!isDesktop || open) {
            panelRef.current.style.visibility = 'visible'
            return destroy
        }
        destroy()
        timeoutRef.current = setTimeout(() => {
            if (!panelRef.current && !open) {
                return
            }
            panelRef.current.style.visibility = 'hidden'
        }, delay)
        return destroy
    }, [open, delay, isDesktop])
    return panelRef
}

function Panel({ children, isOpen, bgOnClick, ...rest }) {
    const handleNavAction = useCallback(() => {
        bgOnClick?.()
    }, [bgOnClick])
    const panelRef = usePanelTimeoutHidden({ open: isOpen })

    return (
        <PanelStyled {...rest}>
            <button
                type="button"
                aria-label="close"
                className={clsx('navigationPanel_close', isOpen ? `open` : `closed`)}
                onClick={handleNavAction}
            />
            <div ref={panelRef} className={clsx('navigationPanel', isOpen ? `open` : `closed`)}>
                <div className="navigationPanel_inner">{children}</div>
            </div>
        </PanelStyled>
    )
}

Panel.propTypes = {
    isOpen: PropTypes.bool,
    bgOnClick: PropTypes.func
}

Panel.defaultProps = {
    isOpen: false,
    bgOnClick: undefined
}

export default withErrorBoundary(Panel, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[NavigationPanel]: ', error, componentStack)
    }
})
