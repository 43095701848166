import { useCallback } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useNavigationStore } from '@hmn/rtl-web-core/context/navigation'
import { useGTM } from '@hmn/rtl-web-core/hooks'

import { CloseIcon, HamburgerIcon, Icon } from '../../../Icon'
import styles from './NavigationButton.style'

const NavigationButtonStyled = styled.div(props => ({ ...styles(props) }))

function NavigationButton({ className }) {
    const [{ isOpen: isNavigationOpen }, setNavigationStore] = useNavigationStore()
    const { sendDataToGTM } = useGTM()
    const handleNavAction = useCallback(() => {
        setNavigationStore({ isOpen: !isNavigationOpen, searchIsOpen: false })
        sendDataToGTM(
            {
                eventCategory: 'Navigation',
                eventLabel: 'Navigation Icon'
            },
            true
        )
    }, [isNavigationOpen, sendDataToGTM, setNavigationStore])

    return (
        <NavigationButtonStyled className={className}>
            <button type="button" onClick={handleNavAction} aria-label="Izbornik">
                <Icon icon={isNavigationOpen ? CloseIcon : HamburgerIcon} />
            </button>
        </NavigationButtonStyled>
    )
}
NavigationButton.propTypes = {
    className: PropTypes.string
}

NavigationButton.defaultProps = {
    className: null
}

export default NavigationButton
