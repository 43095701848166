import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { NavigationActions, SearchActions } from '@hmn/rtl-web-core/actions'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button, netButtonVariants } from '../../Button'
import { Icon, SearchIcon } from '../../Icon'
import styles from './Bar.style'

const SEARCH_ROUTE = '/pretrazivanje'
const SEARCH_PARAM = 'q'

const BarStyled = styled.form(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Bar({ className, variant, autoFocus, ...rest }) {
    const dispatch = useDispatch()
    const router = useRouter()
    const [searchQuery, setSearchQuery] = useState(router?.query?.[SEARCH_PARAM] || '')
    const searchInput = useRef()

    const handleSearchSubmit = useCallback(
        e => {
            dispatch(SearchActions.close())
            dispatch(NavigationActions.close())
            e.preventDefault()
            router.push({ pathname: SEARCH_ROUTE, query: { [SEARCH_PARAM]: searchQuery } })
        },
        [searchQuery]
    )

    const { isOpen: isSearchOpen } = useSelector(state => state.search)

    useEffect(() => {
        if (isSearchOpen && autoFocus) {
            setTimeout(() => searchInput?.current?.focus(), 300) // @TODO: check if 300ms is maybe too long
        }
    }, [isSearchOpen, autoFocus])

    useEffect(() => {
        if (router.pathname === SEARCH_ROUTE) {
            setSearchQuery(router.query[SEARCH_PARAM] || '')
        }
    }, [router.pathname, router.query[SEARCH_PARAM]])

    return (
        <BarStyled autoComplete="off" variant={variant} onSubmit={handleSearchSubmit} {...rest} id="search_bar">
            <input
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                type="text"
                name="search"
                className="searchInput"
                placeholder="Upiši traženi pojam..."
                ref={searchInput}
            />

            <Button className="searchButton" variant={netButtonVariants.BETA} width="100" type="submit" role="button">
                {variant === 'navigation' ? <Icon icon={SearchIcon} /> : 'Pretraži'}
            </Button>
        </BarStyled>
    )
}

const searchBarVariants = Object.freeze({
    DEFAULT: 'default',
    NAVIGATION: 'navigation'
})

Bar.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(searchBarVariants)]),
    autoFocus: PropTypes.bool
}

Bar.defaultProps = {
    className: undefined,
    variant: searchBarVariants.DEFAULT,
    autoFocus: false
}

export { searchBarVariants }
export default withErrorBoundary(Bar, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SearchBar]: ', error, componentStack)
    }
})
