import styled from '@emotion/styled'
import Head from 'next/head'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { StickyWrapper } from '@hmn/rtl-web-core/components/StickyWrapper'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { PrevNextButton } from '..'
import styles from './PrevNext.net.style'

const PrevNextStyled = styled.div(props => ({ ...styles(props) }))

function PrevNext({
    className,
    previousButtonTitle,
    previousArticleTitle,
    previousHref,
    previousHrefAs,
    previousOnClick,
    nextButtonTitle,
    nextArticleTitle,
    nextHref,
    nextHrefAs,
    nextOnClick,
    page,
    total,
    titleTagComponent,
    sticky,
    isHunk,
    variant,
    ...rest
}) {
    const [isDesktop] = useBreakpoints('md')
    if (!(nextHref || nextOnClick) && !(previousHref || previousOnClick)) {
        return null
    }
    return (
        <>
            <PrevNextStyled className={className} variant={variant} {...rest}>
                <ConditionalWrapper condition={sticky} Wrapper={StickyWrapper} className="prevNext_wrapper">
                    <div className="prevNext">
                        <div className="prevNext_button_wrapper">
                            <div className="prevNext_button" key="prev_next_prev">
                                <PrevNextButton
                                    buttonTitle={previousButtonTitle}
                                    href={previousHref}
                                    hrefAs={previousHrefAs}
                                    onClick={previousOnClick}
                                    title={previousArticleTitle}
                                    titleTagComponent={titleTagComponent}
                                    variant={variant}
                                />
                            </div>
                            <div key="prev_next_pager" className="prevNext_count">
                                {page && total && variant === 'alpha' && `${page}/${total}`}
                            </div>
                            <div className="prevNext_button" key="prev_next_next">
                                <PrevNextButton
                                    buttonTitle={
                                        // eslint-disable-next-line no-nested-ternary
                                        page === total && !isHunk
                                            ? isDesktop
                                                ? 'Nastavi na'
                                                : 'Naslovnica'
                                            : nextButtonTitle
                                    }
                                    href={nextHref}
                                    hrefAs={nextHrefAs}
                                    onClick={nextOnClick}
                                    title={nextArticleTitle}
                                    titleTagComponent={titleTagComponent}
                                    variant={variant}
                                    reverseAligned
                                    isLastPage={page === total}
                                />
                            </div>
                        </div>
                    </div>
                </ConditionalWrapper>
            </PrevNextStyled>
            <Head>
                {previousHref && (
                    <link
                        rel="prev"
                        href={`${
                            !previousHref?.match(/http(s){0,1}:/gi) ? process.env.NEXT_PUBLIC_APP_ROOT_NET : ''
                        }${previousHref}`}
                    />
                )}
                {nextHref && (
                    <link
                        rel="next"
                        href={`${
                            !nextHref?.match(/http(s){0,1}:/gi) ? process.env.NEXT_PUBLIC_APP_ROOT_NET : ''
                        }${nextHref}`}
                    />
                )}
            </Head>
        </>
    )
}

const prevNextVariants = Object.freeze({
    ALPHA: 'alpha', // prev-next article
    BETA: 'beta' // hunk of the day article
})

PrevNext.propTypes = {
    titleTagComponent: PropTypes.elementType,
    className: PropTypes.string,
    previousButtonTitle: PropTypes.string,
    previousArticleTitle: PropTypes.string,
    previousHref: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    previousHrefAs: PropTypes.string,
    previousOnClick: PropTypes.func,
    nextButtonTitle: PropTypes.string,
    nextArticleTitle: PropTypes.string,
    nextHref: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    nextHrefAs: PropTypes.string,
    nextOnClick: PropTypes.func,
    page: PropTypes.number,
    total: PropTypes.number,
    sticky: PropTypes.bool,
    variant: PropTypes.oneOf([...Object.values(prevNextVariants)]),
    isHunk: PropTypes.bool
}

PrevNext.defaultProps = {
    titleTagComponent: 'h3',
    className: undefined,
    previousButtonTitle: 'PRETHODNA',
    previousArticleTitle: undefined,
    previousHref: undefined,
    previousHrefAs: undefined,
    previousOnClick: undefined,
    nextButtonTitle: 'SLJEDEĆA',
    nextArticleTitle: undefined,
    nextHref: undefined,
    nextHrefAs: undefined,
    nextOnClick: undefined,
    page: undefined,
    total: undefined,
    sticky: false,
    variant: prevNextVariants.ALPHA,
    isHunk: false
}
export { prevNextVariants }
export default withErrorBoundary(PrevNext, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[PrevNext]: ', error, componentStack)
    }
})
