import { toRem, toRems } from '../../helpers'

const style = ({ theme, variant }) =>
    // const config = {}
    ({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
        ...(variant === 'menu' && {
            height: toRem(50),
            borderTop: `${toRem(1)} solid ${theme.colors.border.default}`,
            borderRight: `${toRem(1)} solid ${theme.colors.border.default}`,
            [theme.breakpoints.up('md')]: {
                border: 'none'
            }
        }),
        ...(variant === 'header' && {
            [theme.breakpoints.up('md')]: {
                padding: toRems([17, 10, 20])
            }
        }),
        background: theme.colors.freemail?.background?.default || theme.colors.devWarning,

        span: {
            display: 'inline-block',
            flex: '0 0 auto',
            width: 'auto',

            verticalAlign: 'middle'
        },
        '&:hover': {
            background: theme.colors.freemail?.background?.hover || theme.colors.devWarning,
            '.Freemail': {
                '&_title, &_icon': {
                    color: theme.colors.freemail?.textColor?.hover || theme.colors.devWarning
                }
            }
        },
        '.Freemail': {
            '&_title': {
                fontSize: toRem(16),
                marginTop: '0.6em',

                lineHeight: '1em',
                textTransform: 'uppercase',
                marginLeft: variant === 'menu' ? toRem(10) : 0,
                fontWeight:
                    variant === 'menu' ? theme.typography.font.weight.bold : theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.normal,
                fontFamily: theme.typography.fontFamilyAlt,

                color: theme.colors.freemail?.textColor?.default || theme.colors.devWarning,
                transition: 'color 300ms ease-in-out',
                [theme.breakpoints.up('md')]: {
                    marginTop: '0.4em'
                }
            },
            '&_icon': {
                color: theme.colors.freemail?.textColor?.default || theme.colors.devWarning,
                transition: 'color 300ms ease-in-out',
                path: {
                    fill: 'currentColor'
                }
            }
        }
    })

export default style
