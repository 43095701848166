import styled from '@emotion/styled'
import getConfig from 'next/config'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Icon, SearchIcon } from '../../Icon'
import styles from './Details.style'

const { publicRuntimeConfig = {} } = getConfig()
const { assetPrefix } = publicRuntimeConfig

const DetailsStyled = styled.form(props => ({ ...styles(props) }))

function Details({ resultCount, query, isArchive, ...rest }) {
    return (
        <DetailsStyled {...rest} id="search_details">
            {resultCount ? (
                <div className="detailsContainer">
                    <Icon className="detailsContainer_icon" icon={SearchIcon} size={14} />

                    <p>
                        Pronašli smo <span className="highlight">{resultCount}</span> rezultata{' '}
                        {!isArchive && (
                            <>
                                za pojam
                                <span className="highlight">
                                    {' “'}
                                    {query}
                                    {'” '}
                                </span>
                            </>
                        )}
                    </p>
                </div>
            ) : (
                <div className="detailsContainer_empty">
                    <img src={`${assetPrefix || ''}/svg/no-results-icon.svg`} alt="Nema rezultata" />

                    <p>
                        Nažalost nema rezultata za pojam
                        <span className="highlight">
                            {' "'}
                            {query}
                            {'" '}
                        </span>
                    </p>
                </div>
            )}
        </DetailsStyled>
    )
}

Details.propTypes = {
    resultCount: PropTypes.number,
    query: PropTypes.string,
    isArchive: PropTypes.bool
}

Details.defaultProps = {
    resultCount: undefined,
    query: undefined,
    isArchive: false
}

export default withErrorBoundary(Details, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Details]: ', error, componentStack)
    }
})
