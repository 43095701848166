import { toRem } from '../../../../../../../../helpers/theme'

const style = ({ theme, variant, isAmp }) => ({
    display: 'flex',
    marginTop: ['header', 'partner', 'info'].some(v => v === variant) ? 0 : toRem(18),
    textAlign: 'left',

    '.Animated': {
        '&_primaryItem': {
            ...(['header', 'partner', 'info'].some(v => v === variant) && {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                height: variant === 'header' && toRem(20)
            })
        },
        '&_secondaryItems': {},
        '&_left': {
            width: toRem(32),
            height: 'auto',
            marginRight: toRem(10)
        },
        '&_item': {
            '&_wrapper': {
                overflow: 'hidden',
                height: 0
            }
        }
    },
    ...(!isAmp && {
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            display: 'inline-block',
            ...(variant === 'header' && {
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column'
            })
        }
    })
})

const panelMotionVariants = {
    'open-menu-item': {
        height: 'auto',
        transition: {
            duration: 0.2,
            ease: 'easeInOut',
            staggerChildren: 0.03
        }
    },
    'closed-menu-item': {
        height: 0,
        transition: {
            duration: 0.2,
            ease: 'easeInOut',
            staggerChildren: 0.03,
            staggerDirection: -1
        }
    }
}

const itemMotionVariants = {
    'open-menu-item': {
        opacity: 1
    },
    'closed-menu-item': {
        opacity: 0
    }
}
export { itemMotionVariants, panelMotionVariants }
export default style
