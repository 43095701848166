import { toRem } from '../../helpers/theme'

const style = ({ theme }) => {
    const { panel } = theme.global.navigation
    const config = {
        maxWidth: {
            md: toRem(panel.maxWidth.md),
            sm: toRem(panel.maxWidth.sm),
            xs: toRem(panel.maxWidth.xs)
        }
    }
    return {
        pointerEvents: 'none',
        '.navigationPanel': {
            pointerEvents: 'all',
            zIndex: theme.zIndex.navigationMobile,
            height: 'auto',
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            background: theme.colors.navigation?.menu?.background || theme.colors.devWarning,
            maxWidth: config.maxWidth.xs,
            transition: 'transform 0.4s',
            willChange: 'transform',
            '&.open': {
                transform: 'translateX(0vw)',
                pointerEvents: 'all',
                transitionTimingFunction: 'ease-out',
                transitionDelay: '50ms',
                [theme.breakpoints.up('md')]: {
                    transform: '',
                    opacity: 1
                }
            },
            '&.closed': {
                transform: 'translateX(-100vw)',
                pointerEvents: 'none',
                transitionTimingFunction: 'none',
                [theme.breakpoints.up('md')]: {
                    transform: '',
                    opacity: 0
                }
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: config.maxWidth.sm
            },

            [theme.breakpoints.up('md')]: {
                left: 'auto',
                maxWidth: config.maxWidth.md,
                zIndex: theme.zIndex.navigationDesktop,
                transition: 'opacity 0.2s',
                willChange: 'opacity'
            },

            '&_inner': {
                width: '100%',
                maxWidth: config.maxWidth.xs,
                [theme.breakpoints.up('sm')]: {
                    maxWidth: config.maxWidth.sm
                },
                [theme.breakpoints.up('md')]: {
                    maxWidth: config.maxWidth.md
                }
            },

            '&_close': {
                zIndex: theme.zIndex.navigationDesktop,
                transition: 'opacity',
                willChange: 'opacity',

                pointerEvents: 'all',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                background: 'black',
                opacity: 0.3,
                '&.open': {
                    opacity: 0.3,
                    pointerEvents: 'all',
                    transitionTimingFunction: 'ease-out'
                },
                '&.closed': {
                    opacity: 0,
                    pointerEvents: 'none',
                    transitionTimingFunction: 'ease-in'
                }
            }
        }
    }
}

export default style
