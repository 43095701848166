import { convertHexToRGBA } from '../../helpers'

const navigationStyle = ({ theme }) => {
    // Define variables for frequently used theme properties
    const $link = '#a5a5a5'
    const $navBg = '#f8f8f8'
    const $fontFamily = theme.typography.fontFamilyAlt
    const $fontWeight = theme.typography.font.weight.bold
    const $defaultColor = theme.colors?.navigation?.menu?.item?.default?.default
    const $hoverColor = theme.colors?.navigation?.menu?.item?.default?.hover

    return {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        margin: '0 -15px',
        padding: '0 15px',
        boxSizing: 'content-box',
        userSelect: 'none',
        cursor: 'grabbing',

        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '3px',
            left: '15px',
            zIndex: '10',
            display: 'block',
            width: '10px',
            height: '100%',
            background: `linear-gradient(90deg, ${convertHexToRGBA(
                theme.colors.mainBackground,
                1
            )} 0%, ${convertHexToRGBA(theme.colors.mainBackground, 0)} 100%)`
        },

        '&:after': {
            left: 'auto',
            right: '15px',
            background: `linear-gradient(90deg, ${convertHexToRGBA(
                theme.colors.mainBackground,
                0
            )} 0%, ${convertHexToRGBA(theme.colors.mainBackground, 1)} 100%)`
        },

        '.subnavigation': {
            '&__list': {
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                flexWrap: 'nowrap',
                width: '100%',
                // draggable related
                overflowX: 'auto',
                WebkitOverflowScrolling: 'touch',
                scrollbarWidth: 'none',

                '&::-webkit-scrollbar': {
                    display: 'none'
                },

                '&.snap': {
                    scrollSnapType: 'x proximity'
                }
            },

            '&__item': {
                flex: '0 0 auto',
                margin: '0 5px',
                scrollSnapAlign: 'start',

                // Net, Danas, DanasW
                '&:nth-of-type(-n + 3)': {
                    paddingLeft: '12px'
                },

                '&:last-child': {
                    paddingRight: '10px'
                },

                // INFO: This is a temporary fix for the logo sizes
                // '&.is_nethr img': { width: 'auto' },
                '&.is_danashr img': {
                    width: '88px'
                },
                '&.is_danashr': {
                    display: ['netDark', 'danasDark'].includes(theme.name) ? 'none' : 'inline'
                },
                '&.is_danashrw img': {
                    width: '88px'
                },
                '&.is_danashrw': {
                    display: ['netDark', 'danasDark'].includes(theme.name) ? 'inline' : 'none'
                },
                '&.is_voyo img': { width: '58px' },
                // '&.is_rtlhr img': { width: 'auto' },
                '&.is_zenahr img': { marginTop: '-2px' },

                [theme.breakpoints.up('md')]: {
                    '&:nth-of-type(1), &:nth-of-type(2)': {
                        paddingLeft: '0'
                    },

                    '&:last-child': {
                        paddingRight: '0'
                    }
                }
            },

            '&__link': {
                height: '39px',
                margin: '0 30px 0 0',
                fontSize: '16px',
                lineHeight: '25px',
                fontFamily: $fontFamily,
                fontWeight: $fontWeight,
                transition: 'color 300ms',
                color: $defaultColor,

                '&:hover': {
                    color: $hoverColor
                },

                'img, svg': {
                    width: 'auto',
                    height: 'auto'
                },

                '&.has_logo': {
                    margin: '0 5px'
                }
            }
        },

        '.more-btn': {
            position: 'absolute',
            top: '3px',
            right: 0,
            zIndex: '11',
            padding: '4px 10px 4px 20px',
            whiteSpace: 'nowrap',
            color: $defaultColor,
            // background: 'linear-gradient(90deg, rgba(246,246,246,0) 0%, rgba(246,246,246,1) 30%)',
            background: `linear-gradient(90deg, ${convertHexToRGBA(
                theme.colors.mainBackground,
                1
            )} 0%, ${convertHexToRGBA(theme.colors.mainBackground, 0)}, 30%)`,
            border: 'none',
            cursor: 'pointer',

            '&:hover, &:focus': {
                textShadow: '0 0 1px #000'
            }
        },

        '.more-container': {
            position: 'absolute',
            top: '100%',
            right: 0,
            zIndex: '10',
            backgroundColor: $navBg,
            whiteSpace: 'nowrap',
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
            borderTop: '1px solid lighten($more, 25%)',

            li: {
                padding: '10px 15px',
                background: $navBg,
                color: $link,
                textDecoration: 'none',
                borderTop: '1px solid lighten($link, 25%)',
                borderRight: '1px solid lighten($link, 25%)',
                display: 'block',
                transition: 'background-color 0.3s',

                '&:first-of-type': {
                    borderTop: 'none'
                }
            }
        },

        '.is_danashr': {
            // maxWidth: 100,
            '.is_section_danas &': {
                display: 'none'
            }
        },
        '.is_nethr': {
            display: 'none',

            '.is_section_danas &': {
                display: 'inline-block'
            },

            svg: {
                width: '100%',
                height: '100%'
            }
        }
    }
}

const linkColors = ({ theme }) => {
    const categoryColors = theme.colors?.category || {}

    return Object.entries(categoryColors).reduce((styles, [category, color]) => {
        styles[`.is_${category} .subnavigation__link:hover, .is_${category} .subnavigation__link.is_active`] = {
            color
        }
        return styles
    }, {})
}

const style = props => ({ ...navigationStyle(props), ...linkColors(props) })

export default style
