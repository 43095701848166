import { toRem } from '../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        size: toRem(theme.global.header.height.xs.open),
        background: {
            default: theme.colors.navigation?.menu?.button?.background?.default || theme.colors.devWarning,
            hover: theme.colors.navigation?.menu?.button?.background?.hover || theme.colors.devWarning
        },
        color: {
            default: theme.colors.navigation?.menu?.button?.icon?.default || theme.colors.devWarning,
            hover: theme.colors.navigation?.menu?.button?.icon?.hover || theme.colors.devWarning
        }
    }

    return {
        position: 'relative',
        display: 'block',
        height: '100%',
        button: {
            background: config.background.default,
            color: config.color.default,
            width: config.size,
            height: '100%',
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'background, color',
            span: {
                margin: 'auto',
                width: 'fit-content'
            },
            '&:hover': {
                background: config.background.hover,
                color: config.color.hover
            },
            svg: {
                path: {
                    fill: 'currentColor'
                }
            }
        }
    }
}

export default style
