import { responsive, toRem, toRems } from '../../../helpers/theme'

const style = ({ theme }) => ({
    width: '100%',
    '& .detailsContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        ...responsive([
            { padding: toRems([15, 0]) },
            { padding: toRems([15, 0]) },
            { padding: toRems([38, 0]) },
            { padding: toRems([38, 0]) },
            { padding: toRems([38, 0]) }
        ]),

        '&_icon': {
            marginTop: toRem(5)
        },

        '& p': {
            marginLeft: toRem(8),
            fontSize: toRem(16),
            lineHeight: toRem(24)
        }
    },
    '& .detailsContainer_empty': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...responsive([
            { padding: toRems([90, 0]) },
            { padding: toRems([90, 0]) },
            { padding: toRems([200, 0]) },
            { padding: toRems([200, 0]) },
            { padding: toRems([200, 0]) }
        ]),

        '& p': {
            fontSize: toRem(18),
            textAlign: 'center',
            ...responsive([
                { marginTop: toRem(30) },
                { marginTop: toRem(30) },
                { marginTop: toRem(50) },
                { marginTop: toRem(50) },
                { marginTop: toRem(50) }
            ])
        }
    },
    '& .highlight': {
        color: theme.colors.search.highlight,
        fontWeight: 700
    }
})

export default style
