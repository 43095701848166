import { useCallback } from 'react'
import styled from '@emotion/styled'

// import { SearchActions } from '@hmn/rtl-web-core/actions'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useSearchIsOpenState } from '@hmn/rtl-web-core/context/navigation'
import { useBreakpoints, useGTM } from '@hmn/rtl-web-core/hooks'

import BasePanel from '../../Panel/Panel.component'
import { SearchBar } from '../Bar'
import styles from './Panel.style'

const SearchPanelInner = styled.div(props => ({ ...styles(props) }))

function Panel({ ...rest }) {
    const [isSearchOpen, setSearchIsOpen] = useSearchIsOpenState()
    const [isDesktop] = useBreakpoints('md')
    const { sendDataToGTM } = useGTM()
    const handleNavAction = useCallback(() => {
        setSearchIsOpen(!isSearchOpen)
        sendDataToGTM(
            {
                eventCategory: 'Search',
                eventLabel: 'Search Icon'
            },
            true
        )
        if (document.body) {
            document.body.style.overflowY = 'auto'
        }
    }, [setSearchIsOpen, sendDataToGTM])
    if (!isDesktop) {
        return null
    }
    return (
        <BasePanel isOpen={isSearchOpen} bgOnClick={handleNavAction} {...rest}>
            <SearchPanelInner>
                <SearchBar autoFocus />
            </SearchPanelInner>
        </BasePanel>
    )
}

export default withErrorBoundary(Panel, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SearchPanel]: ', error, componentStack)
    }
})
