import { toRem } from '@hmn/rtl-net-ui/helpers/theme'

const style = ({ theme, isWorldCup }) => ({
    position: 'relative',
    backgroundColor: !isWorldCup && theme.colors.background.mainDefault,

    '& > main': {
        paddingTop: toRem(theme.global.header.height.default),
        ...(isWorldCup &&
            theme.name !== 'netDark' && {
                [theme.breakpoints.up('md')]: {
                    backgroundImage: 'url(/images/sahovnica3.jpg), url(/images/sahovnica4.jpg)',
                    backgroundPosition: 'left top, right top',
                    backgroundRepeat: 'no-repeat, no-repeat'
                }
            })
        // [theme.breakpoints.only('xs')]: {
        //     paddingTop: toRem(theme.global.header.height.xs)
        // }
    }
})

export default style
