import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, reverseAligned }) => {
    const config = {
        variants: {
            alpha: {}
        },
        xs: {
            display: 'flex',
            justifyContent: reverseAligned ? 'flex-end' : 'flex-start',
            button: {
                paddingInner: toRem(10)
            },
            arrow: {
                size: toRem(26),
                margin: toRem(6)
            }
        },
        md: {
            display: 'flex',
            justifyContent: !reverseAligned ? 'flex-end' : 'flex-start',
            button: {
                paddingInner: toRem(25)
            },
            arrow: {
                size: toRem(23),
                margin: toRem(10)
            }
        }
    }
    return {
        width: '100%',
        flex: '1 1 50%',
        padding: toRems([0, 10]),
        display: config?.xs?.display,
        justifyContent: config?.xs?.justifyContent,
        [theme.breakpoints.down('sm')]: {
            padding: toRems([0, 5])
        },
        [theme.breakpoints.up('md')]: {
            display: config?.md?.display,
            justifyContent: config?.md?.justifyContent
        },
        '.prevNextButton': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: reverseAligned ? 'flex-start' : 'flex-end',
            float: reverseAligned ? 'left' : 'right',
            fontSize: toRem(15),
            lineHeight: 1.2,
            color: theme.colors.black,
            fontWeight: theme.typography.fontWeight.bold,
            letterSpacing: '.01em',
            ...(reverseAligned
                ? {
                      paddingLeft: config.xs.button.paddingInner
                  }
                : {
                      paddingRight: config.xs.button.paddingInner
                  }),

            // [theme.breakpoints.down('sm')]: {
            //     letterSpacing: '.01em'
            // },
            [theme.breakpoints.up('md')]: {
                paddingTop: config.md.button.paddingY,
                paddingBottom: config.md.button.paddingY,
                ...(reverseAligned
                    ? {
                          paddingLeft: config.md.button.paddingInner
                      }
                    : {
                          paddingRight: config.md.button.paddingInner
                      })
            },
            '&_content': {
                fontSize: toRem(22),
                lineHeight: 1.2,
                textAlign: reverseAligned ? 'left' : 'right',
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'block'
                },
                color: theme.colors.text.accent,
                fontWeight: theme.typography.fontWeight.bold
            },
            '&_title': {
                display: 'flex',
                flexDirection: reverseAligned ? 'row-reverse' : 'row',
                alignItems: 'center',
                fontWeight: 900,
                svg: {
                    width: config.xs.arrow.size,
                    height: config.xs.arrow.size,
                    transform: !reverseAligned ? 'rotate(180deg)' : null,
                    ...(reverseAligned
                        ? {
                              marginLeft: config.xs.arrow.margin
                          }
                        : {
                              marginRight: config.xs.arrow.margin
                          }),
                    [theme.breakpoints.up('md')]: {
                        width: config.md.arrow.size,
                        height: config.md.arrow.size
                    }
                },
                span: {
                    fontSize: toRem(15),
                    lineHeight: 1.2
                }
            }
        }
    }
}

export default style
