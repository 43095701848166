import React from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useNavigation } from '@hmn/rtl-web-core/hooks'

import {
    FacebookHollowNetIcon,
    Icon,
    InstagramHollowNetIcon,
    NetLogoIcon,
    RssIcon,
    RtlHrLogo,
    TiktokNetIcon,
    XHollowNetIcon
    // YoutubeNetIcon
} from '../Icon'
import { Link } from '../Link'
import { Menu, menuVariants, Navigation, navigationVariants } from '../Navigation'
import styles from './Footer.style'

const FooterStyled = styled.footer(props => ({ ...styles(props) }))

const socialChannels = [
    // { name: 'Youtube', url: 'https://www.youtube.com/', component: YoutubeNetIcon, width: '22px' },
    { name: 'Tiktok', url: 'https://www.tiktok.com/@net.hr', component: TiktokNetIcon, width: '18px' },
    { name: 'Facebook', url: 'https://www.facebook.com/net.hr', component: FacebookHollowNetIcon, width: '18px' },
    { name: 'Instagram', url: 'https://www.instagram.com/net.hr_/', component: InstagramHollowNetIcon, width: '22px' },
    { name: 'X', url: 'https://x.com/nethr', component: XHollowNetIcon, width: '20px' }
]

function Footer({ navigationData, ...rest }) {
    const [infoLinks, digitalProductsLinks, partnerLinks] = useNavigation(navigationData, [
        'info',
        'digital',
        'partner'
    ])
    const isAmp = useAmp()
    const gtmData = {
        eventCategory: 'Navigation',
        eventLabel: 'Navigation Item',
        eventValue: 'Footer'
    }
    const hasInfo = !!infoLinks?.length
    const hasDigital = !!digitalProductsLinks?.length
    const hasPartners = !!partnerLinks?.length

    const uid = useUIDSeed()

    return (
        <FooterStyled className="intextAdIgnore" isAmp={isAmp} {...rest}>
            {navigationData && (
                <Navigation
                    navigationData={navigationData}
                    className="Footer_main"
                    variant={navigationVariants.FOOTER}
                />
            )}
            <div className="Footer_container">
                <div className="Footer_info">
                    <div className="Footer_channels">
                        {socialChannels.map((item, index) => (
                            <Link
                                key={uid(item.name || index)}
                                Wrapper={Link}
                                href={item.url}
                                target="_blank"
                                title={item.name}
                                className="Footer_channel">
                                <Icon
                                    className={`channel_icon is_${item.name}`}
                                    icon={item.component}
                                    viewBox="0 0 18 18"
                                    width={item.width}
                                    height="16"
                                />
                                <span className="channel_name">{item.name}</span>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="Footer_info">
                    <div className="Footer_info_left">
                        {hasInfo && (
                            <Menu
                                className="Footer_info_menu"
                                variant={menuVariants.INFO}
                                gtmData={gtmData}
                                links={infoLinks}
                                highlightActive={false}
                            />
                        )}
                        <Link href="/rss" className="Footer_rss">
                            <Icon icon={RssIcon} viewBox="0 0 15 15" /> RSS
                        </Link>
                    </div>

                    <div className="Footer_info_right">
                        <Link href="https://www.rtl.hr/" isExternal className="Footer_logo_rtl" title="RTL - Hrvatska">
                            <Icon icon={RtlHrLogo} viewBox="00 0 90 30" />
                        </Link>
                        <Link href="/" as="/" className="Footer_logo_net" title="Net.hr">
                            <Icon icon={NetLogoIcon} viewBox="0 0 90 22" />
                        </Link>
                    </div>
                </div>
                {(hasDigital || hasPartners) && (
                    <div className="Footer_partners">
                        {hasDigital && (
                            <Menu
                                className="Footer_partners_item"
                                title="Rtl Digitalni Proizvodi"
                                variant={menuVariants.PARTNER}
                                gtmData={gtmData}
                                links={digitalProductsLinks}
                                highlightActive={false}
                            />
                        )}
                        {hasPartners && (
                            <Menu
                                className="Footer_partners_item"
                                title="Partnerski portali"
                                variant={menuVariants.PARTNER}
                                gtmData={gtmData}
                                links={partnerLinks}
                                highlightActive={false}
                            />
                        )}
                    </div>
                )}

                <span className="Footer_signature">&copy;{new Date().getFullYear()}. Sva prava pridržana.</span>
            </div>
        </FooterStyled>
    )
}

Footer.propTypes = { navigationData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]) }

Footer.defaultProps = {
    navigationData: null
}

export default withErrorBoundary(Footer, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Footer]: ', error, componentStack)
    }
})
