import { useCallback } from 'react'

import { useNavigationStore } from '@hmn/rtl-web-core/context/navigation'
import { useGTM } from '@hmn/rtl-web-core/hooks'

import BasePanel from '../../../Panel/Panel.component'

function Panel({ children, ...rest }) {
    const [{ isOpen }, setNavigationStore] = useNavigationStore()
    const { sendDataToGTM } = useGTM()
    const handleNavAction = useCallback(() => {
        setNavigationStore({ isOpen: false, searchIsOpen: false })
        sendDataToGTM(
            {
                eventCategory: 'Navigation',
                eventLabel: 'Navigation Icon'
            },
            true
        )
        if (document.body) {
            document.body.style.overflowY = 'auto'
        }
    }, [setNavigationStore, sendDataToGTM])
    return (
        <BasePanel isOpen={isOpen} bgOnClick={handleNavAction} {...rest}>
            {children}
        </BasePanel>
    )
}

export default Panel
