import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './Content.style'

const ContentStyled = styled.div(props => ({ ...style(props) }))

function Content({ children, isWorldCup }) {
    return <ContentStyled isWorldCup={isWorldCup}>{children}</ContentStyled>
}

Content.propTypes = {
    isWorldCup: PropTypes.bool
}

Content.defaultProps = {
    isWorldCup: false
}

export default Content
