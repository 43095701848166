import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { useGTM } from '@hmn/rtl-web-core/hooks'

import { Link } from '../../../../../../../Link'
import styles from './Secondary.style'

const SecondaryStyled = styled.span(props => ({ ...styles(props) }))

function Secondary({ className, variant, href, onClick, title, gtmData, active, color, ...rest }) {
    const isAmp = useAmp()
    const { sendDataToGTM } = useGTM()
    if (!title) {
        return null
    }
    const handleClick = () => {
        sendDataToGTM(gtmData, true)
        if (onClick) {
            onClick()
        }
    }

    const isInternalRegex = new RegExp(
        `${process.env.NEXT_PUBLIC_APP_ROOT_NET.replace(/http(s)*:\/\/|www./gim, '')}|^(/|#)`
    )

    return (
        <SecondaryStyled
            className={className}
            hasHref={!!href || !!onClick}
            variant={variant}
            color={!isAmp && color}
            active={active}
            isAmp={isAmp}>
            {(href && (
                <Link
                    className="secondaryMenuItem"
                    onClick={handleClick}
                    href={href}
                    title={title}
                    isExternal={!href?.match(isInternalRegex)}
                    {...rest}>
                    {title}
                </Link>
            )) ||
                (onClick && (
                    <button
                        title={title}
                        aria-label={title}
                        type="button"
                        onClick={handleClick}
                        className="secondaryMenuItem">
                        {title}
                    </button>
                )) || <span className="secondaryMenuItem">{title}</span>}
        </SecondaryStyled>
    )
}

const secondaryMenuItemVariants = Object.freeze({
    NAVIGATION: 'navigation',
    FOOTER: 'footer'
})

Secondary.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    active: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf([...Object.values(secondaryMenuItemVariants)]),
    gtmData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

Secondary.defaultProps = {
    className: undefined,
    title: undefined,
    color: undefined,
    active: false,
    href: undefined,
    onClick: undefined,
    variant: secondaryMenuItemVariants.NAVIGATION,
    gtmData: undefined
}

export { secondaryMenuItemVariants }
export default Secondary
