import { toRem } from '../../../../../../../../../../helpers'

const style = ({ theme }) => ({
    '.menuArrow': {
        '&_circle': {
            width: toRem(32),
            height: toRem(32),
            borderRadius: toRem(32),
            border: `${toRem(1)} solid ${theme.colors.navigation?.menu?.arrow?.border || theme.colors.devWarning}`,
            background: theme.colors.navigation?.menu?.arrow?.background || theme.colors.devWarning
        },
        '&_wrapper': {
            fontSize: toRem(16),
            lineHeight: toRem(16),
            textAlign: 'center'
        },
        '&_icon': {
            marginTop: toRem(-4), // adjust icon center
            color: theme.colors.navigation?.menu?.arrow?.icon || theme.colors.devWarning,
            path: {
                fill: 'currentColor'
            }
        }
    }
})
const arrowMotionVariants = {
    open: {
        scaleY: 1,
        transition: {
            duration: 0.3,
            ease: 'easeInOut'
        }
    },
    closed: {
        scaleY: -1,
        transition: {
            duration: 0.3,
            ease: 'easeInOut'
        }
    }
}
export { arrowMotionVariants }
export default style
