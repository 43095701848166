/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// Navigation.js
import { useEffect } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import Link from 'next/link'
import PropTypes from 'prop-types'

import { useHeaderState } from '../Header/useHeaderState'
import styles from './NavigationHeader.style'

// NavigationHeader.style
const SubavHeaderStyled = styled.div(props => ({ ...styles(props) }))

function SubavLogosHeader({ navigationData, className }) {
    const uid = useUIDSeed()
    const { isOpen } = useHeaderState()
    const classNameParsed = clsx('subnavigation', `is_${isOpen}`, className)

    useEffect(() => {
        document.querySelector('.main_layout')?.classList.add(isOpen ? 'subnav_show' : 'subnav_hide')
        document.querySelector('.main_layout')?.classList.remove(!isOpen ? 'subnav_show' : 'subnav_hide')
    }, [isOpen])

    return (
        <SubavHeaderStyled className={classNameParsed}>
            <ul className="subnavigation__list _snap">
                {navigationData.map(
                    (item, index) =>
                        item.title && (
                            <li
                                key={uid(`${item.title} ${index}`)}
                                className={clsx('subnavigation__item', `is_${item.class}`)}>
                                <Link
                                    className="subnavigation__link has_logo"
                                    hrefLang="hr"
                                    title={item.title}
                                    aria-label={item.title}
                                    href={item.href}
                                    target={item.target}>
                                    <img src={item.logoUrl} alt={item.title} />
                                </Link>
                            </li>
                        )
                )}
            </ul>
        </SubavHeaderStyled>
    )
}

SubavLogosHeader.propTypes = {
    navigationData: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            logoUrl: PropTypes.string
        })
    ).isRequired
}

export default SubavLogosHeader
