/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// Navigation.js
import React, { useEffect, useRef, useState } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import Link from 'next/link'
import PropTypes from 'prop-types'

import styles from './NavigationHeader.style'

// lets style this component
const SubavHeaderStyled = styled.div(props => ({ ...styles(props) }))

// NavigationHeader.style
function SubavHeader({ navigationData, activeCategory, activeSubcategory, categoryName, className }) {
    const uid = useUIDSeed()
    const classNameParsed = clsx(`subnavigation`, className)

    const navRef = useRef(null)
    const [isDragActive, setIsDragActive] = useState(false)
    const [dragStartX, setDragStartX] = useState(0)
    const [startScrollLeft, setStartScrollLeft] = useState(0)
    const [moreListItems, setMoreListItems] = useState([])
    const [showMoreButton, setShowMoreButton] = useState(false)

    const updateMoreList = () => {
        if (navRef.current) {
            const navWidth = navRef.current.offsetWidth
            let totalWidth = 0

            const newMoreListItems = Array.from(navRef.current.querySelectorAll('li'))
                .filter(item => {
                    totalWidth += item.offsetWidth
                    return totalWidth + 30 > navWidth // 30px compensates margins
                })
                .map(item => item.cloneNode(true))

            setMoreListItems(newMoreListItems)
        }
    }

    useEffect(() => {
        updateMoreList() // Update more list on mount
        // Handle window resize event
        const handleResize = () => {
            updateMoreList()
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        setMoreListItems([])
        updateMoreList()
    }, [activeCategory])

    const toggleDragActiveState = isActive => {
        setIsDragActive(isActive)
        if (navRef.current) {
            navRef.current.classList.toggle('active', isActive)
        }
    }

    const startDragging = e => {
        e.persist()
        toggleDragActiveState(true)
        setDragStartX(e.pageX - navRef.current.offsetLeft)
        setStartScrollLeft(navRef.current.scrollLeft)
        if (navRef.current) {
            navRef.current.classList.remove('snap')
        }
    }

    const stopDragging = () => {
        toggleDragActiveState(false)
        if (navRef.current) {
            navRef.current.classList.add('snap')
        }
    }

    const dragMove = e => {
        if (!isDragActive) {
            return
        }
        e.preventDefault()
        const x = e.pageX - navRef.current.offsetLeft
        const walk = (x - dragStartX) * 1 // Controls scroll speed
        navRef.current.scrollLeft = startScrollLeft - walk
    }

    const toggleMoreContainer = () => {
        setShowMoreButton(!showMoreButton)
        updateMoreList()
    }

    return (
        <SubavHeaderStyled className={classNameParsed}>
            <ul
                className={clsx('subnavigation__list', `is_${categoryName}`)}
                ref={navRef}
                onMouseDown={startDragging}
                onMouseLeave={stopDragging}
                onMouseUp={stopDragging}
                onMouseMove={dragMove}>
                {navigationData.map(
                    (item, index) =>
                        item.title && (
                            <li key={uid(`${item.title} ${index}`)} className="subnavigation__item">
                                <Link
                                    className={clsx(
                                        'subnavigation__link',
                                        activeSubcategory === item.href && 'is_active'
                                    )}
                                    hrefLang="hr"
                                    title={item.title}
                                    aria-label={item.title}
                                    href={item.href}>
                                    {item.title}
                                </Link>
                            </li>
                        )
                )}
            </ul>
            {!!moreListItems.length && (
                <button className="more-btn" type="button" onClick={toggleMoreContainer}>
                    🠋
                </button>
            )}
            {showMoreButton && !!moreListItems.length && (
                <ul className="more-container">
                    {moreListItems.map((item, index) => (
                        <li key={uid(`${item.title} ${index}`)}>{item.textContent}</li>
                    ))}
                </ul>
            )}
        </SubavHeaderStyled>
    )
}

SubavHeader.propTypes = {
    navigationData: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string
        })
    ).isRequired,
    activeCategory: PropTypes.string,
    activeSubcategory: PropTypes.string,
    categoryName: PropTypes.string
}

SubavHeader.defaultProps = {
    activeCategory: '',
    activeSubcategory: '',
    categoryName: ''
}

export default SubavHeader
