import { keyframes } from '@emotion/react'

import { isColor } from '../../../../helpers'

const moveBackground = keyframes`
    0% { background-position-x: 0; }
    10%, 100% { background-position-x: 100%; }
`

const style = ({ theme, logoColor, logoOpen }) => ({
    '.is_netHrLogo': {
        position: 'relative',

        '&:before': {
            content: "''",
            position: 'absolute',
            top: '-22px',
            left: '-22px',
            zIndex: 1,
            display: 'block',
            width: '72px',
            height: '53px',
            scale: '0.43',
            backgroundImage: 'url(/images/xmass-hats.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 0',
            backgroundSize: 'cover',
            animation: `${moveBackground} 5s steps(3) infinite`
        },

        '.is_section_danas &': {
            display: 'none'
        },

        svg: {
            position: 'relative',
            top: '-6px',
            marginLeft: '10px',
            width: '125px',
            height: '100%',

            'path.colortxt': {
                fill: theme.colors.logoTextColors
            },

            'path.colorme': {
                fill: (isColor(logoColor) ? logoColor : theme.colors.category[logoColor]) || theme.colors.brand,
                transition: 'fill 300ms',
                [theme.breakpoints.up('md')]: {
                    fill:
                        (logoOpen && (isColor(logoColor) ? logoColor : theme.colors.category[logoColor])) ||
                        theme.colors.brand
                }
            }
        }
    },

    '.is_danasHrLogo': {
        display: 'none',

        '.is_section_danas &': {
            display: 'block'
        },

        svg: {
            width: '120px',
            height: '100%',
            marginTop: '-3px',
            marginLeft: '10px',

            ...(['netDark', 'danasDark'].includes(theme.name) && {
                '.colorme': {
                    fill: 'white'
                }
            }),

            [theme.breakpoints.up('md')]: {
                width: '155px',
                marginTop: 0,
                marginLeft: '0'
            }
        }
    },

    '&.is_faux': {
        '.is_netHrLogo': {
            display: 'none',
            position: 'relative',
            top: '5px',
            width: '87px',
            height: '100%',

            '&:before': {
                display: 'none'
            },

            svg: {
                width: '100%',
                height: '100%',

                'path.colorme': {
                    fill: theme.colors.netOrange
                }
            },

            '.is_section_danas &': {
                display: 'block'
            }
        },

        '.is_danasHrLogo': {
            display: 'block',
            position: 'relative',
            top: '3px',
            width: '104px',
            height: '100%',

            '.is_section_danas &': {
                display: 'none'
            },
            svg: {
                width: '100%',
                height: '100%',

                ...(['netDark', 'danasDark'].includes(theme.name) && {
                    '.colorme': {
                        fill: 'white'
                    }
                })
            }
        }
    }
})
export default style
