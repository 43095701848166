import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { useGTM } from '@hmn/rtl-web-core/hooks'

import { Link } from '../../../../../../../Link'
import styles from './Primary.style'

const PrimaryStyled = styled.span(props => ({ ...styles(props) }))

function Primary({ className, variant, href, onClick, title, gtmData, active, categoryColor, ...rest }) {
    const { sendDataToGTM } = useGTM()
    const isAmp = useAmp()
    if (!title) {
        return null
    }

    const handleClick = () => {
        sendDataToGTM(gtmData, true)
        if (onClick) {
            onClick()
        }
    }

    const isInternalRegex = new RegExp(
        `${process.env.NEXT_PUBLIC_APP_ROOT_NET.replace(/http(s)*:\/\/|www./gim, '')}|^(/|#)`
    )

    return (
        <PrimaryStyled
            className={className}
            hasHref={!!href || !!onClick}
            variant={variant}
            categoryColor={!isAmp && categoryColor}
            isAmp={isAmp}
            active={active}>
            {(href && (
                <Link
                    className="primaryMenuItem"
                    onClick={handleClick}
                    href={href}
                    title={title}
                    isExternal={!href?.match(isInternalRegex)}
                    {...rest}>
                    {title}
                </Link>
            )) ||
                (onClick && (
                    <button
                        title={title}
                        aria-label={title}
                        type="button"
                        onClick={handleClick}
                        className="primaryMenuItem">
                        {title}
                    </button>
                )) || <span className="primaryMenuItem">{title}</span>}
        </PrimaryStyled>
    )
}

const primaryMenuItemVariants = Object.freeze({
    HEADER: 'header',
    NAVIGATION: 'navigation',
    FOOTER: 'footer',
    INFO: 'info',
    PARTNER: 'partner'
})
Primary.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    categoryColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    active: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf([...Object.values(primaryMenuItemVariants)]),
    gtmData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

Primary.defaultProps = {
    className: undefined,
    title: undefined,
    categoryColor: undefined,
    active: false,
    href: undefined,
    onClick: undefined,
    variant: primaryMenuItemVariants.HEADER,
    gtmData: undefined
}

export { primaryMenuItemVariants }
export default Primary
